export function ProgressBar(props: {
  percentFull: number;
  className?: string;
}) {
  return (
    <div className={"w-24 h-3 bg-gray-100 rounded-xl " + props.className}>
      <div
        className={`h-full rounded-xl bg-blue-400`}
        style={{ width: `${props.percentFull}%` }}
      ></div>
    </div>
  );
}
