import { ArrowBack, ArrowForward, MoreHoriz } from "@material-ui/icons";
import React, { useState } from "react";
import Pagination from "rc-pagination";
import en_US from "rc-pagination/lib/locale/en_US";
import { classNames } from "../utils/classNames";

export function PagePagination(props: {
  isDisabled?: boolean;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  defaultPageSize: number;
  pageSize: number;
  totalSize: number;
  isSectional: boolean;
  className?: string;
}) {
  const {
    isDisabled = false,
    pageNumber,
    setPageNumber,
    defaultPageSize,
    pageSize,
    totalSize,
    isSectional,
    className,
  } = props;

  function onChange(current: number, pageSize: number) {
    setPageNumber(current);
  }

  return (
    <div
      className={classNames(
        isSectional ? "relative top-3" : "absolute left-4 bottom-5",
        "w-max bg-white border border-gray-200 rounded-md box-border"
      )}
      style={{
        boxShadow:
          "0px 10px 15px -3px rgba(48, 67, 87, 0.1), 0px 4px 6px -2px rgba(48, 67, 87, 0.05)",
      }}
    >
      <Pagination
        disabled={isDisabled}
        className={classNames("flex items-center", className || "")}
        current={pageNumber}
        total={totalSize}
        defaultPageSize={defaultPageSize}
        hideOnSinglePage={false}
        onChange={onChange}
        locale={en_US}
        showTotal={(total) =>
          pageSize === total
            ? total === 1
              ? `Showing ${total} item`
              : `Showing ${total} items`
            : `Showing ${pageSize} of ${total}`
        }
        showSizeChanger={false}
        showLessItems={true}
        prevIcon={<ArrowBack style={{ fontSize: 18 }} />}
        nextIcon={<ArrowForward style={{ fontSize: 18 }} />}
        jumpPrevIcon={<MoreHoriz style={{ fontSize: 18 }} />}
        jumpNextIcon={<MoreHoriz style={{ fontSize: 18 }} />}
      />
    </div>
  );
}
