import axios from "axios";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import useSWR from "swr";
import FetchedContentView from "../components/FetchedContentView";
import Layout from "../components/Layout";
import { ProgressBar } from "../components/ProgressBar";
import { RatingStars } from "../components/RatingStars";
import { useUser } from "../hooks/useUser";
import usePagination from "../hooks/usePagination";
import { apiUrls, fetcher } from "../utils/api";
import { getRatingWeightedMean } from "../utils/buildings";
import { getImgixUrlWithParams } from "../utils/images";
import { Building, PaginatedBuildings } from "../types/api";

export default function Properties() {
  const defaultPageSize: number = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const user = useUser();
  const { data, error } = useSWR<PaginatedBuildings>(
    `${apiUrls.buildings}?page=${currentPage}`,
    fetcher
  );

  const { pageNumber, pagePagination } = usePagination(
    currentPage,
    defaultPageSize,
    data?.results?.length ?? 0,
    data?.count ?? 0
  );

  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [pageNumber]);

  return (
    <Layout title="Sugar Portal: Properties">
      <div className="flex flex-row items-center flex-wrap flex-grow-0 flex-shrink-0 h-20 mx-4 sm:mx-6 md:mx-8 border-b border-gray-200 select-none">
        <div className="flex items-center flex-grow text-2xl font-bold">
          Properties
        </div>
        {user?.organization.sync_integration?.is_enabled && (
          <div className="flex items-center px-3 py-1 bg-yellow-100 rounded-xl">
            <Image src={`/integration_appfolio.png`} width="16" height="16" />
            <div className="pl-1.5 text-sm font-medium">
              Manage your properties in your{" "}
              {user?.organization.sync_integration?.software_type} dashboard
            </div>
          </div>
        )}
      </div>
      {/* header */}

      <div className="flex-1 pb-8 px-8 mb-8 overflow-y-scroll">
        <div className="hidden md:grid grid-flow-col grid-cols-property-table-row gap-6 px-4 pt-4 pb-2">
          <div className="text-sm font-bold text-gray-700">Property</div>
          <div className="text-sm font-bold text-gray-700 text-center">
            Floors
          </div>
          <div className="text-sm font-bold text-gray-700 text-center">
            Units
          </div>
          <div className="text-sm font-bold text-gray-700 text-center">
            Residents
          </div>
          <div className="text-sm font-bold text-gray-700">Occupancy</div>
          <div className="text-sm font-bold text-gray-700">Avg. Rating</div>
        </div>

        <FetchedContentView data={data} error={error}>
          <ul>
            {data?.results &&
              data?.results.map((b) => (
                <PropertyItem key={b.id} building={b} />
              ))}
          </ul>
        </FetchedContentView>
      </div>
      {/* scroller */}

      {pagePagination}
    </Layout>
  );
}

function PropertyItem(props: { building: Building }) {
  const { building } = props;
  const ratingsWeightedMean = getRatingWeightedMean(building);

  return (
    <li>
      <Link href={`/properties/${building.id}`}>
        <a className="block hover:bg-gray-50 bg-white text-gray-900 rounded-md mb-4 border border-gray-200">
          {/* Main building row */}
          <div className="grid sm:grid-cols-1 md:grid-flow-col md:grid-cols-property-table-row gap-6 px-4 py-4">
            {/* property-info */}
            <div className="md:flex md:flex-1 min-h-0 mb-4 md:text-left md:mb-0">
              <div className="w-full md:flex-grow-0 md:flex-shrink-0 md:h-16 md:w-16 mr-4 rounded-lg overflow-hidden md:mb-0">
                {building.primary_image ? (
                  <Image
                    src={getImgixUrlWithParams(building.primary_image, {
                      w: 64 * 2,
                      h: 64 * 2,
                      mask: "corners",
                      "corner-radius": 8,
                      fit: "crop",
                    })}
                    height={64 * 2}
                    width={64 * 2}
                    className="object-cover h-full w-full"
                    alt="Property"
                  />
                ) : (
                  <img
                    src="https://www.dekra-product-safety.com/sites/default/files/field_section_background_image/building_placeholder_500x332.png"
                    className="object-cover h-full w-full"
                    alt="Property"
                  />
                )}
              </div>
              {/* .property-image */}
              <div className="flex flex-col property-meta flex-grow flex-shrink w-full truncate">
                <p className="w-full font-semibold truncate">{building.name}</p>
                <p className="w-full text-sm text-gray-700 truncate">
                  {building.address}, {building.address2}
                </p>
                <p className="w-full text-sm text-gray-700 truncate">
                  {building.city}, {building.state} {building.zipcode}
                </p>
              </div>
            </div>
            {/* /property-info */}

            {/* floors */}
            <div className="md:flex items-center justify-center mb-4 md:mb-0">
              <p className="font-semibold">
                {building.analytics.num_floors}
                <span className="md:hidden lg:hidden"> floors</span>
              </p>
            </div>
            {/* /floors */}

            {/* units */}
            <div className="md:flex items-center justify-center mb-4 md:mb-0">
              <p className="font-semibold">
                {building.analytics.num_units}
                <span className="md:hidden lg:hidden"> units</span>
              </p>
            </div>
            {/* /units */}

            {/* residents */}
            <div className="md:flex items-center justify-center mb-4 md:mb-0">
              <p className="font-semibold">
                {building.analytics.num_residents}
                <span className="md:hidden lg:hidden"> residents</span>
              </p>
            </div>
            {/* /residents */}

            {/* % occupied */}
            <div className="flex items-center mb-4 md:mb-0">
              {building.analytics.num_units > 0 ? (
                <>
                  <div className="w-8 mr-3 font-semibold text-right">
                    {building.analytics.occupancy_percentage}%
                  </div>
                  <ProgressBar
                    percentFull={building.analytics.occupancy_percentage}
                  />
                </>
              ) : (
                <div className="pl-3 text-gray-500 font-bold">- - -</div>
              )}
            </div>
            {/* /occupancy */}

            {/* rating */}
            <div className="flex items-center mb-4 md:mb-0">
              {ratingsWeightedMean !== null ? (
                <div className="font-semibold truncate flex items-center">
                  <span className="mr-2">{ratingsWeightedMean}</span>
                  <RatingStars rating={ratingsWeightedMean} />
                </div>
              ) : (
                <p className="font-semibold italic text-gray-500 text-sm">
                  No ratings configured
                </p>
              )}
            </div>
            {/* /rating */}
          </div>
        </a>
      </Link>
    </li>
  );
}
