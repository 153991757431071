import { FlipCameraAndroidTwoTone } from "@material-ui/icons";
import React from "react";

export default function LoadingView() {
  return (
    <div className="flex items-center justify-center w-full py-10 px-6 text-gray-700 text-sm font-semibold italic leading-none">
      <FlipCameraAndroidTwoTone className="!w-4 !text-lg mr-2 text-blue-700 animate-spin" />
      loading ...
    </div>
  );
}
