import dynamic from "next/dynamic";
const StarRatings = dynamic(() => import("react-star-ratings"), {
  ssr: false,
});

export function RatingStars(props: { rating: number }) {
  return (
    <StarRatings
      // TODO: ts library support
      //@ts-ignore
      rating={props.rating}
      starRatedColor="#E5C13E"
      starEmptyColor="#FDF9EE"
      starDimension="16px"
      starSpacing="2px"
      numberOfStars={5}
      name="rating"
    />
  );
}
