import React, { useState } from "react";
import { PagePagination } from "../components/PagePagination";

export default function usePagination(
  currentPage: number,
  defaultPageSize: number,
  pageSize: number,
  totalSize: number,
  sectional: boolean = false
) {
  const [pageNumber, setPageNumber] = useState<number>(currentPage);

  return {
    pageNumber,
    setPageNumber,
    pagePagination: (
      <PagePagination
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        defaultPageSize={defaultPageSize}
        pageSize={pageSize}
        totalSize={totalSize}
        isSectional={sectional}
      />
    ),
  };
}
