import React from "react";
import ErrorView from "./ErrorView";
import LoadingView from "./LoadingView";

export default function FetchedContentView(props: {
  data: any | undefined;
  error?: any | undefined;
  children: React.ReactNode;
}) {
  const { data, error, children } = props;

  if (!data) {
    return <LoadingView />;
  }
  if (error) {
    return <ErrorView error={error} />;
  }
  return <>{children}</>;
}
